import AbstractBlock from "./../_abstract/abstract-block";

export default class DefaultBlock extends AbstractBlock {
  init () {
    super.init()
  }

  initEvents () {
    super.initEvents()
  }

  destroyEvents () {
    super.destroyEvents()
  }
}
