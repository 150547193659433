import ClassFactory from "../class-factory";

export default class AbstractPage {
  constructor (page, pageType) {
    this.pageType = pageType

    this.classFactory = new ClassFactory()
    this.cont = page
    this.blocks = []

    this.createBlocks()
    this.init()
  }

  init () {
  }

  destroy () {
    this.destroyEvents()

    if (this.blocks !== null) {
      for (let i = 0; i < this.blockLength; i++) {
        this.blocks[i].destroy();
      }
    }
  }

  initEvents () {
  }

  destroyEvents () {
  }

  createBlocks () {
    this.blocks = this.cont.querySelectorAll('.js-block')
    this.blockLength = this.blocks.length

    for (let i = 0; i < this.blockLength; i++) {
      this.initSingleBlock(this.blocks[i]);
    }
  }

  initSingleBlock (block) {
    let blockType = block.getAttribute('data-block');

    return this.classFactory.getBlockInstance(block, blockType);
  }
}