import DefaultBlock from "./default-block"
import * as globals from './../utils/globals'
import {TweenLite} from 'gsap'
import ScrollToPlugin from './../vendors/ScrollToPlugin'

export default class HeaderLinks extends DefaultBlock {
  init () {
    super.init()

    this.links = this.cont.querySelectorAll('a')
    this.linkIds = []
    this.scrollBuffer = 50

    for (var x = 0; x < this.links.length; x++) {
      this.linkIds.push(this.links[x].getAttribute('href'))
    }
  }

  initEvents () {
    super.initEvents()

    for (var x = 0; x < this.links.length; x++) {
      this.links[x].addEventListener('click', (e) => {
        e.preventDefault()
      })
      this.links[x].addEventListener('click', this.scrollToSection.bind(this, x))
    }
  }

  destroyEvents () {
    super.destroyEvents()
  }

  scrollToSection (x) {
    let target = document.querySelector(this.linkIds[x])
    let targetY = globals.w.scroll + target.getBoundingClientRect().top

    TweenLite.to(globals.win, 1, {scrollTo: {y: targetY - this.scrollBuffer}, ease: Power4.easeInOut})
  }
}
