import * as globals from './../globals'
import {debounce} from './../debounce'

/**
 * Scroll
 *
 */
export function initScroll() {
  const els = document.querySelectorAll('[data-scrolled]')

  function scrollWatcher() {
    if (els.length === 0) return false

    const defaultOffsetPercentage = 30
    let offsets = []
    let points = []

    const initialGetVars = () => {
      for (let x = 0; x < els.length; x++) {
        let customOffset = (els[x].getAttribute('data-scrolled-offset') !== null) ? parseInt(els[x].getAttribute('data-scrolled-offset')) : defaultOffsetPercentage
        let customPercentage = customOffset/100 // Convert to a decimal to use as percentage

        offsets.push(customPercentage)

        points.push(window.pageYOffset + els[x].getBoundingClientRect().top + (globals.w.height*customPercentage))
      }
    }

    const getVars = () => {
      points = []

      for (let x = 0; x < els.length; x++) {
        points.push(window.pageYOffset + els[x].getBoundingClientRect().top + (globals.w.height*offsets[x]))
      }
    }

    initialGetVars();
    window.addEventListener('resize', debounce(getVars, 500));

    let tracker = [];

    for (let x = 0; x < els.length; x++) {
      tracker.push(0);
    }

    let maxFps = 10

    let now,
        delta

    let then = Date.now(),
        interval = 1000 / maxFps

    const watchScroll = () => {
      now = Date.now()
      delta = now - then

      if (delta > interval) {

        let scroll = globals.w.scroll + globals.w.height;

        for (var x = 0; x < tracker.length; x++) {
          if (tracker[x] !== 1 && scroll >= points[x]) {
            tracker[x] = 1;
            els[x].setAttribute('data-scrolled', true);
          }
          if (tracker[x] != 0 && scroll < points[x]) {
            tracker[x] = 0;
            els[x].setAttribute('data-scrolled', false);
          }
        }

        then = now - (delta % interval)

      }

      globals.scrollAnim = requestAnimationFrame(watchScroll)
    }

    globals.scrollAnim = requestAnimationFrame(watchScroll)
  }

  return scrollWatcher()
}