let doc = document
let win = window
let body = doc.body

let w = {
  width:  win.innerWidth,
  height: win.innerHeight,
  scroll: win.pageYOffset
}

let scrollAnim

const updateDimensions = () => {
  w.width = win.innerWidth
  w.height = win.innerHeight
}

const updateScroll = () => {
  w.scroll = win.pageYOffset
}

win.addEventListener('resize', updateDimensions)
win.addEventListener('scroll', updateScroll)

export {
  doc,
  win,
  body,
  w,
  scrollAnim
}