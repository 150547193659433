import DefaultPage from "./pages/default-page";
import HomePage from "./pages/home-page";

import DefaultBlock from "./blocks/default-block";
import HeaderLinks from "./blocks/header-links";
import Hero from "./blocks/hero";
import InstagramSlider from "./blocks/instagram";
import GalleryViewer from "./blocks/gallery";
import MenuButtons from "./blocks/menus";

export default class ClassFactory {
  getPageInstance(page, pageType) {

    switch(pageType) {
      case 'home':
        return new HomePage(page, pageType)
      default:
        console.log('"' + pageType + '" has no defined route, using DefaultPage.')
        return new DefaultPage(page, pageType)
    }
  }

  getBlockInstance(block, blockType) {

    switch(blockType) {
      case 'header-links':
        return new HeaderLinks(block, blockType)
      case 'hero':
        return new Hero(block, blockType)
      case 'instagram':
        return new InstagramSlider(block, blockType)
      case 'gallery':
        return new GalleryViewer(block, blockType)
      case 'menus':
        return new MenuButtons(block, blockType)
      default:
        console.log('"' + blockType + '" has no defined class, using DefaultBlock.')
        return new DefaultBlock(block, blockType)
    }
  }
}