import DefaultBlock from "./default-block";

export default class InstagramSlider extends DefaultBlock {
  init () {
    super.init()

    this.offset = 0
    this.imageCount = this.cont.getElementsByClassName('instagram__images')[0].childElementCount
    this.prevButton = document.getElementsByClassName('instagram__images-link--prev')[0]
    this.nextButton = document.getElementsByClassName('instagram__images-link--next')[0]

    this.prevSlideBindEvent = this.prevSlide.bind(this)
    this.nextSlideBindEvent = this.nextSlide.bind(this)
  }

  initEvents () {
    super.initEvents()

    this.prevButton.addEventListener('click', this.prevSlideBindEvent)
    this.nextButton.addEventListener('click', this.nextSlideBindEvent)
  }

  destroyEvents () {
    super.destroyEvents()

    this.prevButton.removeEventListener('click', this.prevSlideBindEvent)
    this.nextButton.removeEventListener('click', this.nextSlideBindEvent)
  }

  prevSlide () {
    if (this.offset > 0)
      this.offset--

    this.setSlide()
  }

  nextSlide () {
    if (this.offset < this.imageCount - 3)
      this.offset++

    this.setSlide()
  }

  setSlide () {
    this.cont.setAttribute('data-offset', this.offset)

    if (this.offset === this.imageCount - 3)
      this.cont.setAttribute('data-offset-end', true)
    else
      this.cont.setAttribute('data-offset-end', false)
  }
}
