export default class AbstractBlock {
  constructor (block, blockType) {
    this.blockType = blockType

    this.cont = block

    this.init()
    this.initEvents()
  }

  init () {
  }

  destroy () {
    this.destroyEvents()
  }

  initEvents () {
  }

  destroyEvents () {
  }
}