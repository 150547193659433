import DefaultBlock from "./default-block";

export default class MenuButtons extends DefaultBlock {
  init () {
    super.init()

    this.current = 1
    this.buttons = this.cont.getElementsByClassName('menus__links-item')
  }

  initEvents () {
    super.initEvents()

    for (var x = 0; x < this.buttons.length; x++) {
      this.buttons[x].addEventListener('mouseenter', this.setActive.bind(this, x))
    }
  }

  destroyEvents () {
    super.destroyEvents()
  }

  setActive (x) {
    this.cont.setAttribute('data-active', x + 1)
  }
}
