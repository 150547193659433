import * as globals from './../globals'
import * as Utils from './../utils'

/**
 * Any input that has a parent with a class of 'field' will be
 * assigned 'active' and 'inactive' functionality
 *
 * @param   {Element}    target
 */

export function formFields (target = globals.doc) {
  /**
   * Search area is set incase this auto init is run again after an ajax call
   */
  const searchArea = target

  const fields = searchArea.querySelectorAll('.field input, .field textarea')

  if (fields.length === 0) return false

  let parents = []

  for (let x = 0; x < fields.length; x++) {
    fields[x].addEventListener('focus', active)
    fields[x].addEventListener('blur', checkActive)

    loadCheckActive(fields[x])
  }
}

function active (e) {
  let target = e.target

  Utils.setAttribute(target.parentNode, 'focus', 'true')
}

function checkActive (e) {
  let target = e.target

  if (target.value)
    Utils.setAttribute(target.parentNode, 'focus', 'true')
  else
    Utils.setAttribute(target.parentNode, 'focus', 'false')
}

function loadCheckActive (el) {
  if (el.value)
    Utils.setAttribute(el.parentNode, 'focus', 'true')
  else
    Utils.setAttribute(el.parentNode, 'focus', 'false')
}