import DefaultBlock from "./default-block";

export default class SampleBlock extends DefaultBlock {
  init () {
    super.init()

    this.next = 2
    this.imageCount = this.cont.childElementCount
  }

  initEvents () {
    super.initEvents()

    this.interval = window.setInterval(this.heroSwitch.bind(this), 5000)
  }

  destroyEvents () {
    super.destroyEvents()

    window.clearInterval(this.interval)
  }

  heroSwitch (e) {
    this.cont.setAttribute('data-active-image', this.next)

    if (this.next < this.imageCount)
      this.next++
    else
      this.next = 1
  }
}
