/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @see     https://davidwalsh.name/javascript-debounce-function
 * @param   {Function}    func
 * @param   {Number}      wait
 * @param   {Boolean}     immediate
 * @returns {Function}
 */
export function debounce(func, wait = 500, immediate) {
    let timeout

  return () => {
    let context = this, args = arguments

    let later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    let callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
