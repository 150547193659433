import DefaultBlock from "./default-block"
import * as globals from './../utils/globals'

export default class GalleryViewer extends DefaultBlock {
  init () {
    super.init()

    this.offset = 0
    this.transitioning = false

    this.imageLinks = document.getElementsByClassName('gallery__single-image')
    this.viewMoreLink = document.getElementsByClassName('gallery__view-more')[0]

    this.images = this.cont.getElementsByClassName('gallery__viewer-images')[0].querySelectorAll('div')
    this.imageCount = this.images.length
    this.prevButton = document.getElementsByClassName('gallery__viewer-link--prev')[0]
    this.nextButton = document.getElementsByClassName('gallery__viewer-link--next')[0]

    // Function calls with bind
    this.callPrevSlide = this.prevSlide.bind(this)
    this.callNextSlide = this.nextSlide.bind(this)
    this.callSetChosenImage = this.setChosenImage.bind(this)
    this.callCloseViewer = this.closeViewer.bind(this)
  }

  initEvents () {
    super.initEvents()

    this.prevButton.addEventListener('click', this.callPrevSlide)
    this.nextButton.addEventListener('click', this.callNextSlide)

    for (var x = 0; x < this.imageLinks.length; x++) {
      this.imageLinks[x].addEventListener('click', this.setChosenImage.bind(this, x))
    }

    for (var x = 0; x < this.images.length; x++) {
      this.images[x].addEventListener('click', this.handleImageClick)
    }

    this.viewMoreLink.addEventListener('click', this.setChosenImage.bind(this, 6))
  }

  destroyEvents () {
    super.destroyEvents()
  }

  prevSlide (e) {
    e.stopPropagation()

    if (this.transitioning) return

    if (this.offset > 1)
      this.offset--
    else
      this.offset = this.imageCount

    this.transitioning = true
    this.setSlide()
    setTimeout( () => {
      this.transitioning = false
    }, 1000)
  }

  nextSlide (e) {
    e.stopPropagation()

    if (this.transitioning) return

    if (this.offset < this.imageCount)
      this.offset++
    else
      this.offset = 1

    this.transitioning = true
    this.setSlide()
    setTimeout( () => {
      this.transitioning = false
    }, 1000)
  }

  setSlide () {
    this.cont.setAttribute('data-active-image', this.offset)
  }

  handleImageClick (e) {
    e.stopPropagation()
  }

  setChosenImage (x) {
    this.offset = x + 1
    this.setSlide()

    setTimeout(this.showViewer.bind(this), 20)
  }

  showViewer () {
    this.cont.setAttribute('data-visible', true)

    setTimeout(this.addCloseEvents.bind(this), 500)
  }

  addCloseEvents () {
    globals.body.addEventListener('click', this.callCloseViewer)
  }

  closeViewer () {
    this.cont.setAttribute('data-visible', false)

    globals.body.removeEventListener('click', this.callCloseViewer)
  }
}
