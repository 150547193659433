import $ from 'jquery'
import DefaultPage from "./default-page"

export default class HomePage extends DefaultPage {
  init () {

    super.init()

    this.newsletterForm = document.getElementsByClassName('js-newsletter-form')[0]
    this.contactForm = document.getElementsByClassName('js-contact-form')[0]

    this.initEvents()
  }

  initEvents () {
    super.initEvents()

    this.newsletterForm.addEventListener('submit', this.handleNewsletterForm.bind(this))
    this.contactForm.addEventListener('submit', this.handleContactForm.bind(this))
  }

  destroyEvents () {
    super.destroyEvents()
  }

  handleNewsletterForm (e) {
    e.preventDefault()

    let $this = $(this.newsletterForm)

    console.log(this.serializeObject($this))

    if ($this) {

      $.ajax({
        url: window.location + "/wp-admin/admin-ajax.php",
        type:'POST',
        data: $.extend({
          action: $this.attr('action')
        }, this.serializeObject($this)),
        dataType: 'json',
        success: function(data){
          $this.html('<h3>Thanks for signing up!</h3>');
        },
        error: function(data){
          $this.html('<h3>Sign up unsuccessful.</h3>');
          console.log(data)
        },
      })

    }
  }

  handleContactForm (e) {
    e.preventDefault()

    let $this = $(this.contactForm)

    console.log(this.serializeObject($this))

    if ($this) {

      $.ajax({
        url: window.location + "/wp-admin/admin-ajax.php",
        type:'POST',
        data: $.extend({
          action: $this.attr('action')
        }, this.serializeObject($this)),
        dataType: 'json',
        success: function(data){
          $this.html('<h3>Thanks for your message!</h3>');
        },
        error: function(data){
          $this.html('<h3>Your message could not be sent.</h3>');
          console.log(data)
        },
      })

    }
  }

  serializeObject (obj) {
    let o = {}
    let a = obj.serializeArray()

    $.each(a, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '')
      } else {
        o[this.name] = this.value || ''
      }
    })

    return o
  }
}
